<template>
     <CForm ref="form" class="needs-validation"   >
        <CInput
            placeholder="Email Adres"
            type="email"
            autocomplete="email"
            v-model="data.emailAddress"
            required
        >
            <template #prepend-content>
                <CIcon name="cil-envelope-closed" />
            </template>
        </CInput>
        <CInput
            placeholder="Şifre"
            type="password"
            autocomplete="current-password"
            v-model="data.password"
            :disabled="actionType == 'update'"
            required
        >
            <template #prepend-content>
                <CIcon name="cil-shield-alt" />
            </template>
        </CInput>
        <CInput
            placeholder="Ad"
            type="text"
            autocomplete="text"
            v-model="data.name"
        >
            <template #prepend-content>
                <CIcon name="cil-envelope-closed" />
            </template>
        </CInput>
        <CInput
            placeholder="Soyad"
            type="text"
            autocomplete="text"
            v-model="data.surname"
        >
            <template #prepend-content>
                <CIcon name="cil-envelope-closed" />
            </template>
        </CInput>

        <CSelect
            :value.sync="data.roleId"
            :options="roles"
            placeholder="Rol Adı"
            required
        >
            <template #prepend-content>
                <CIcon name="cil-shield-alt" />
            </template>
        </CSelect>

        <CSelect
            v-if="data.roleId == 10"
            :value.sync="data.productionCompanyId"
            :options="productionCompanies"
            placeholder="Üretici"
            required
        >
            <template #prepend-content>
                <CIcon name="cil-shield-alt" />
            </template>
        </CSelect>

        <CRow form class="form-group">
            <CCol tag="label" sm="2" class="col-form-label">
                <b>Aktif</b>
            </CCol>
            <CCol sm="2" style="margin-left: -15px">
                <CSwitch
                    class="mr-1"
                    size="lg"
                    color="primary"
                    :checked.sync="data.activeFlag"
                />
            </CCol>
            <CCol tag="label" sm="4" class="col-form-label"> </CCol>
        </CRow>
    </CForm>
</template>

<script>
    export default{
        name: "UserForm",
        props:{
            validated: Boolean,
            params: Object,
            actionType: String,
        },
        computed:{
            roles: function() {
                let data = [{value: undefined, label: 'Rol Seçin'}]
                this.$store.getters.allRoles.map(r => data.push({value:r.id, label: r.name}))
                return data
            },
            productionCompanies: function() {
            let data = ['Üretici Seç'];
            this.$store.getters.productionCompanies.map(pc => data.push({label: pc.name, value: pc.id}))
            return data
        },
        },
        watch:{
            params: function(val){
                this.data = {...val};
            },
            actionType:  function(val){
                if(val == 'create')
                    this.data = {}
            }
        },
        data: function(){
            return{
                data: this.params ? {...this.params} : {},
            }
        },
    }
</script>